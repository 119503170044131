<template>
  <b-modal ref="updateProgramsModal" hide-footer title="Update Programs" centered lazy @hide="hideModal" size="md">
    <b-form-group label="Programs" label-for="programs" label-class="form-label">
      <v-select
        id="programs"
        class="form-control v-select-custom"
        label="title"
        v-model="programs"
        :reduce="(item) => item.id"
        placeholder="All Programs"
        :options="allPrograms"
        multiple
        :state="!$v.programs.required && $v.programs.$dirty ? false : null"
      >
      </v-select>
      <b-form-invalid-feedback :state="!$v.programs.required && $v.programs.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="d-flex justify-content-end mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="onSubmit"
        href="#"
        data-toggle="modal"
        :disabled="isLoading"
      >
        <span>{{ 'update' }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UpdateProgramsModal',

  props: {
    showModal: { type: Boolean, default: false },
    index: { type: Number, default: null },
    existingPrograms: { type: Array, default: () => [] },
  },

  data() {
    return {
      programs: [],
      allPrograms: [],
      areProgramsLoading: false,

      isLoading: false,
    };
  },
  validations() {
    return {
      programs: { required },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  methods: {
    ...mapActions('stories', ['updateStories']),
    ...mapActions('program', ['getPublicSchoolPrograms']),
    get,

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getPublicSchoolPrograms({
        school: this.getLoggedInUser.linked_entity.id,
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          programs: this.programs,
        };
        try {
          await this.updateStories({
            id: this.index,
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Programs Updated!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }

        this.programs = [];
        this.$refs.updateProgramsModal.hide();
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async hideModal() {
      this.$emit('close');
      this.programs = [];
    },

    hideModalManual() {
      this.$refs.updateProgramsModal.hide();
      this.hideModal();
    },
  },
  watch: {
    async showModal(value) {
      if (value) {
        await this.fetchPrograms();

        this.programs = this.existingPrograms.map((program) => program.id);
        this.$refs.updateProgramsModal.show();
      }
    },
  },
  mounted() {
    // this.fetchPrograms();
  },
};
</script>

<style>
</style>