<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div class="text-center mt-5 mb-5">
        <b-img :src="logo" width="200" :alt="brand" />
      </div>

      <div class="d-flex justify-content-center">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center" v-if="isVerifying">
            <i class="fas fa-circle-notch fa-spin fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>Verifying Email</h4>

            <p class="text-muted">Verifying your email address. Please wait.</p>
          </div>
          <div class="card-body text-center" v-else-if="isVerified">
            <i class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
            <h4>Email Verified</h4>

            <p class="text-muted">Your email has been successfully verified. Go to home and start using Transition!</p>

            <router-link :to="{ name: 'home' }" class="btn btn-primary mt-2" exact>Back to Home</router-link>
          </div>
          <div class="card-body text-center" v-else>
            <i class="fas fa-times-circle fa-lg text-danger mt-4 mb-4" style="font-size: 3rem" />
            <h4>Cannot Verify Email</h4>

            <p class="text-muted">Your email address cannot be verified. Please contact the administrator.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex';
import Page from '@/components/Page';
import logo from '@/assets/images/logos/logo_blue.png';

export default {
  components: {},
  extends: Page,
  name: 'VerifyEmployerEmail',
  layout: 'blank',

  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
    linkComponent: {
      type: [String, Object],
      default: () => 'b-link',
    },
  },

  data() {
    return {
      title: 'Verify Email',

      isVerifying: true,
      isVerified: false,
    };
  },

  mounted() {
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
    this.verifyEmail();
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('employer', ['verifyEmployerEmail']),

    async verifyEmail() {
      this.isVerifying = true;

      try {
        await this.verifyEmployerEmail({
          uuid: this.$route.params.verificationId,
        });
        this.isVerified = true;
      } catch (_e) {
        this.isVerified = false;
      }

      this.isVerifying = false;
    },
  },
};
</script>
