<template>
  <div class="container">
    <div v-if="isLoading" class="row">
      <div class="col-md-4 d-flex flex-column align-items-center text-center" style="margin-top: 25px">
        <div class="d-flex flex-column align-items-center text-center" style="margin-top: 80px">
          <div style="position: absolute; margin-top: -75px; z-index: 1">
            <b-skeleton size="140px" type="avatar"></b-skeleton>
          </div>
          <div
            class="card text-center p-3"
            style="width: 100%; position: relative; color: white !important; border-radius: 1.5rem"
          >
            <div class="d-flex flex-column align-items-center mt-3">
              <b-skeleton class="mt-5" animation="fade" width="80px"></b-skeleton>

              <b-skeleton class="mt-4" animation="fade" width="270px"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="55%"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="70%"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="70%"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="isMobileScreen ? 'text-center' : ''"
        class="col-md-8 px-2"
        :style="isMobileScreen ? 'margin-top: 12px' : 'margin-top: 15px'"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton v-if="isMobileScreen" width="300px" class="mb-2" type="button"></b-skeleton>
            <b-skeleton animation="fade" width="250px"></b-skeleton>
          </div>
          <div class="ml-auto" v-if="!isMobileScreen">
            <b-skeleton width="140px" type="button"></b-skeleton>
          </div>
        </div>
        <div class="card my-2">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <b-skeleton animation="fade" width="35%"></b-skeleton>
            </div>
          </b-card-header>
          <div class="py-2 px-3" :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton class="mb-4" animation="fade" width="70%"></b-skeleton>

            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
        </div>
        <div class="card">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <b-skeleton animation="fade" width="35%"></b-skeleton>
            </div>
          </b-card-header>
          <div class="py-2 px-3" :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton class="mb-4" animation="fade" width="70%"></b-skeleton>

            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-4 d-flex flex-column align-items-center text-center" style="margin-top: 25px">
        <div class="d-flex flex-column align-items-center text-center" style="margin-top: 80px">
          <div style="position: absolute; margin-top: -75px; z-index: 1">
            <b-avatar
              style="border: 5px solid whitesmoke"
              class="mb-4"
              :src="get(story, 'image_url')"
              variant="light"
              size="150"
            ></b-avatar>
          </div>
          <div
            class="card text-center p-3 bg-dark"
            style="width: 90%; position: relative; color: white !important; border-radius: 1.5rem"
          >
            <h4 class="mb-2" style="padding-top: 65px; color: white !important">
              {{ get(story, 'first_name') }} {{ get(story, 'last_name') }}
            </h4>
            <div class="mb-2">
              <i class="fas fa-graduation-cap icon-16pt"></i>
              {{ get(story, 'school.name') }}
            </div>
            <div class="mb-2">
              <i class="fas fa-map-marker-alt icon-16pt"></i>
              {{ get(story, 'address') }}, {{ get(story, 'city') }}, {{ get(story, 'state') }}
              {{ get(story, 'zipcode') }}, {{ get(story, 'country') }}
            </div>
            <div>
              <i class="fas fa-clock icon-16pt"></i>
              {{ formatFullDate(get(story, 'created_at')) }}
            </div>

            <div class="d-flex align-items-center my-3">
              <hr style="background-color: white; margin-left: 40px; width: 30%; height: 2px" />
              <md-icon style="font-size: 17px">local_library</md-icon>
              <hr style="background-color: white; margin-right: 40px; width: 30%; height: 2px" />
            </div>

            <div>
              <b-button
                v-for="program in get(story, 'programs')"
                :key="program.id"
                class="mb-2 mr-2"
                pill
                style="color: white"
                variant="outline-white"
                size="sm"
                disabled
              >
                {{ program.title }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="isMobileScreen ? 'text-center' : ''"
        class="col-md-8 px-2"
        :style="isMobileScreen ? 'margin-top: 12px' : 'margin-top: 15px'"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <b-dropdown
              v-if="isMobileScreen"
              text=" Manage Spotlight"
              variant="secondary"
              class="btn-width mb-4"
              style="width: 100%"
            >
              <b-dropdown-item @click.prevent="changeStoryVisibility(get(story, 'is_visible'))"
                ><i class="material-icons mr-1">{{ !get(story, 'is_visible') ? 'visibility' : 'visibility_off' }}</i>
                {{ get(story, 'is_visible') ? 'Hide Spotlight' : 'Show Spotlight' }}</b-dropdown-item
              >
              <b-dropdown-item @click.prevent="openProgramsModal(get(story, 'id'))"
                ><i class="material-icons mr-1">local_library</i>Update Programs</b-dropdown-item
              >
              <b-dropdown-item
                :to="{ name: 'student-sponsorship-spotlight' }"
                :disabled="get(getStoryExists, 'recently_posted')"
                v-b-popover.hover.right
                title="You can repost after 60 days of posting existing spotlight"
                ><i class="material-icons mr-1">redo</i>Repost Spotlight</b-dropdown-item
              >
            </b-dropdown>
            <div class="d-flex align-items-center">
              <strong style="font-size: 32px; font-weight: 800">My Spotlight</strong>
              <b-badge v-if="!get(story, 'is_visible')" class="ml-2" variant="danger">Hidden</b-badge>
            </div>
          </div>
          <div class="ml-auto" v-if="!isMobileScreen">
            <b-dropdown text="Manage Spotlight" variant="secondary" class="btn-width" style="width: 100%">
              <b-dropdown-item @click.prevent="changeStoryVisibility(get(story, 'is_visible'))"
                ><i class="material-icons mr-1">{{ !get(story, 'is_visible') ? 'visibility' : 'visibility_off' }}</i>
                {{ get(story, 'is_visible') ? 'Hide Spotlight' : 'Show Spotlight' }}</b-dropdown-item
              >
              <b-dropdown-item @click.prevent="openProgramsModal(get(story, 'id'))"
                ><i class="material-icons mr-1">local_library</i>Update Programs</b-dropdown-item
              >
              <b-dropdown-item
                :to="{ name: 'student-sponsorship-spotlight' }"
                :disabled="get(getStoryExists, 'recently_posted')"
                v-b-popover.hover.right
                title="You can repost after 60 days of posting existing spotlight"
                ><i class="material-icons mr-1">redo</i>Repost Spotlight</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <div class="card my-2" :key="`story-c1-${get(story, 'id', '')}`">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <md-icon class="mr-1">assignment</md-icon>
              <strong style="font-size: 20px">Future Goals</strong>
            </div>
          </b-card-header>
          <div class="py-2 px-3" v-safe-html="get(story, 'future_goals', '')"></div>
        </div>
        <div class="card" :key="`story-c2-${get(story, 'id', '')}`">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <md-icon class="mr-1">assignment</md-icon>
              <strong style="font-size: 20px">Personal Story</strong>
            </div>
          </b-card-header>
          <div class="py-2 px-3" v-safe-html="get(story, 'personal_story', '')"></div>
        </div>
      </div>
    </div>
    <update-programs-modal
      :show-modal="showProgramsModal"
      @close="hideProgramsModal"
      :index="index"
      :existingPrograms="get(story, 'programs')"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate } from '@/common/utils';
import UpdateProgramsModal from './UpdateProgramsModal.vue';

export default {
  components: { UpdateProgramsModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Sponsorship Spotlight',
      story: {},
      showProgramsModal: false,
      index: null,
      isLoading: true,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('stories', ['getStoryExists']),
    isMobileScreen() {
      return this.windowWidth <= 776;
    },
  },

  methods: {
    ...mapActions('stories', ['getStories', 'updateStoryVisibility']),
    get,
    formatFullDate,

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    openProgramsModal(index) {
      this.index = index;
      this.showProgramsModal = true;
    },

    hideProgramsModal() {
      this.showProgramsModal = false;
      this.fetchStory();
    },

    async changeStoryVisibility(is_visible) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to ${
            is_visible ? 'hide your story from everyone' : 'make your story visible to all'
          }.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (isConfirmed) {
          const data = {
            is_visible: !is_visible,
          };
          await this.updateStoryVisibility({
            id: get(this.story, 'id'),
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Story Visibility Updated!' });
          this.fetchStory();
        }
      } catch (error) {
        //
      }
    },

    async fetchStory() {
      this.isLoading = true;
      try {
        this.story = {};

        const res = await this.getStories();

        this.story = res.data.data[0];
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchStory();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.btn-width ::v-deep .dropdown-menu {
  width: 100% !important;
}
</style>