<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div
        class="row mt-5 mb-5"
        v-if="organization"
        :class="!organization.logo_url ? 'd-flex justify-content-center' : ''"
      >
        <div
          v-if="organization.logo_url"
          class="d-flex justify-content-center col-6 align-items-center border-right border-secondary py-2"
        >
          <div>
            <b-img :src="organization.logo_url" width="90" :alt="organization.name" />
          </div>
        </div>

        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="logo" width="170" :alt="brand" />
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5 mb-5">
        <b-img :src="logo" width="200" :alt="brand" />
      </div>

      <card
        :title="$route.query.organization ? `Collaborate with ${organization.name}` : 'Partnership Form'"
        :subtitle="
          $route.query.organization
            ? `Request to join ${organization.name}'s training provider network to help train and upskill aspiring healthcare professionals.`
            : 'Make it easier for college students and workforce program applicants to find your offered programs and gain more experience by completing this form.'
        "
        header-class="text-center  "
        class="navbar-shadow"
        title-class="sch-sign-header-title"
        subtitle-class="sch-sign-header-subtitle"
      >
        <slot />

        <b-form @submit.prevent="onSubmit">
          <page-separator title="Your Information" />

          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="firstname"
                :placeholder="$t('userMsgs.firstName')"
                v-model.trim="user.firstName"
                :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="lastname"
                :placeholder="$t('userMsgs.lastName')"
                v-model.trim="user.lastName"
                :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="user.email"
              :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.email.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <page-separator title="School Information" />

          <!-- Address Fields -->
          <div class="row">
            <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="school.state"
                :reduce="(state) => state.value"
                :placeholder="$t('addressMsgs.state')"
                :options="stateOptions"
                @input="setState"
                :state="!$v.school.state.required && $v.school.state.$dirty ? false : null"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback :state="!$v.school.state.required && $v.school.state.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="$t('addressMsgs.country')"
              label-for="country"
              label-class="form-label"
              class="col-md-6"
            >
              <v-select
                id="country"
                class="form-control v-select-custom"
                label="text"
                v-model="school.country"
                :reduce="(country) => country.value"
                :placeholder="$t('addressMsgs.countryPlaceholder')"
                :options="countryOptions"
                :class="!$v.school.country.required && $v.school.country.$dirty ? 'form-control is-invalid' : ''"
                disabled
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback :state="!$v.school.country.required && $v.school.country.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <b-form-group label="Street Address" label-for="address" label-class="form-label">
            <b-form-input
              id="address"
              placeholder="Street Address"
              v-model="school.address"
              :state="!$v.school.address.required && $v.school.address.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.school.address.required && $v.school.address.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('addressMsgs.zipcode')"
              label-for="zipcode"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="zipcode"
                :placeholder="$t('addressMsgs.zipcode')"
                v-model="school.zipcode"
                :state="!$v.school.zipcode.required && $v.school.zipcode.$dirty ? false : null"
              />
              <b-form-invalid-feedback :state="!$v.school.zipcode.required && $v.school.zipcode.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
              <b-form-input
                id="city"
                :placeholder="$t('addressMsgs.city')"
                v-model="school.city"
                :state="!$v.school.city.required && $v.school.city.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group
            v-if="universitiesOptions.length && !$route.query.organization"
            label="Select All Interested Universities"
            label-for="universities"
            label-class="form-label"
          >
            <div :style="isMobileScreen ? '' : 'max-height: 300px; overflow-y: auto; overflow-x: hidden'" class="">
              <div class="row">
                <div
                  class="mb-2 col-md-6"
                  style="cursor: pointer"
                  v-for="option in universitiesOptions"
                  :key="option.id"
                >
                  <div
                    @click.prevent="selectUniversity(option.id)"
                    style="cursor: pointer; height: 100%"
                    class="card p-3 my-auto"
                  >
                    <b-form-checkbox name="universities" v-model="school.universities" :value="option.id">
                      <div class="d-flex">
                        <img style="width: 40px; height: 40px" class="mr-2" :src="option.small_logo_url" alt="" />
                        <span style="font-size: 16px">
                          {{ option.name }}
                        </span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            v-if="highSchoolOptions.length && !$route.query.organization"
            label="Select All Workforce Programs"
            label-for="universities"
            label-class="form-label"
          >
            <div :style="isMobileScreen ? '' : 'max-height: 300px; overflow-y: auto; overflow-x: hidden'" class="">
              <div class="row">
                <div class="mb-2 col-md-6" style="cursor: pointer" v-for="option in highSchoolOptions" :key="option.id">
                  <div
                    @click.prevent="selectHighSchool(option.id)"
                    style="cursor: pointer; height: 100%"
                    class="card p-3 my-auto"
                  >
                    <b-form-checkbox name="highSchool" v-model="school.interested_best_cities" :value="option.id">
                      <div class="d-flex">
                        <img style="width: 40px; height: 40px" class="mr-2" :src="option.image_url" alt="" />
                        <span style="font-size: 16px">
                          {{ option.name }}
                        </span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group label="Logo" label-for="logo" label-class="form-label" class="row-align-items-center mt-2">
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isUploading.logo">...</span>
                <b-img :src="school.logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="school.logo_url" />
                <md-icon v-else>business</md-icon>
              </fmv-avatar>

              <image-uploader
                @image="(e) => setImage(e, 'logo')"
                :isUploading="isUploading.logo"
                :isRequired="!$v.school.logo_url.required"
                placeholder="Select image to upload."
              />
            </b-media>
          </b-form-group>

          <b-form-group label="School Name" label-for="name" label-class="form-label">
            <b-form-input
              id="name"
              placeholder="Enter Business Name"
              v-model="school.name"
              :state="!$v.school.name.required && $v.school.name.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.school.name.required && $v.school.name.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Contact Email" label-for="email-school" label-class="form-label">
            <b-form-input
              id="email-school"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="school.email"
            />
          </b-form-group>
          <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="school.phone"
              v-mask="'(###) ### - ####'"
              required
            />
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
            <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="school.websiteUrl" />
          </b-form-group>

          <b-form-group :label="$t('schoolMsgs.offeredPrg')" label-for="programs" label-class="form-label">
            <v-select
              id="programs"
              class="form-control v-select-custom"
              label="title"
              v-model="school.programs"
              :reduce="(programs) => programs.id"
              placeholder="Select Offered Program"
              :options="allPrograms"
              multiple
              :class="!$v.school.programs.required && $v.school.programs.$dirty ? 'form-control is-invalid' : ''"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!school.programs" v-bind="attributes" v-on="events" />
              </template>
              <template slot="option" slot-scope="option">
                <span>{{ option.title }}</span>
              </template>
            </v-select>
            <b-form-invalid-feedback :state="!$v.school.programs.required && $v.school.programs.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Tuition Range" label-for="classTuitionRange" label-class="form-label">
            <div class="row">
              <b-form-group label="From" label-for="fromClassTuition" label-class="form-label" class="col-md-6">
                <currency-input
                  id="fromClassTuition"
                  v-model="school.fromClassTuition"
                  class="form-control"
                  placeholder="Enter tuition fee range"
                  :allow-negative="false"
                  :currency="{ prefix: '$', suffix: '' }"
                />
              </b-form-group>
              <b-form-group label="To" label-for="toClassTuition" label-class="form-label" class="col-md-6">
                <currency-input
                  id="toClassTuition"
                  v-model="school.toClassTuition"
                  class="form-control"
                  placeholder="Enter tuition fee range"
                  :allow-negative="false"
                  :currency="{ prefix: '$', suffix: '' }"
                />
              </b-form-group>
            </div>
          </b-form-group>

          <b-form-group label="Average Pass Rate" label-for="passRatio" label-class="form-label">
            <currency-input
              id="passRatio"
              v-model="school.passRatio"
              class="form-control"
              placeholder="Enter pass percentage"
              :allow-negative="false"
              :value-range="{ min: 0, max: 100 }"
              :currency="{ prefix: '', suffix: '%' }"
            />
          </b-form-group>

          <b-form-group label="Average Program Weeks" label-for="avgProgramWeeks" label-class="form-label">
            <b-form-input
              id="avgProgramWeeks"
              v-model="school.avgProgramWeeks"
              class="form-control"
              placeholder="Enter average program weeks"
              type="number"
              :allow-negative="false"
            />
          </b-form-group>

          <b-form-group label="Students Trained" label-for="studentsTrained" label-class="form-label">
            <b-form-input
              id="studentsTrained"
              v-model="school.studentsTrained"
              class="form-control"
              placeholder="Enter number of students trained last year"
              type="number"
              :allow-negative="false"
            />
          </b-form-group>

          <b-form-group label="School Offerings" label-for="bestSchoolOfferings" label-class="form-label">
            <v-select
              id="bestSchoolOfferings"
              class="form-control v-select-custom"
              label="text"
              v-model="school.bestSchoolOfferings"
              :reduce="(item) => item.value"
              placeholder="Select best school offerings"
              :options="BestSchoolOfferingsOptions"
              multiple
            >
            </v-select>

            <b-form-input
              id="other_offerings"
              class="mt-2"
              placeholder="Please specify."
              v-model="school.otherOfferings"
              required
              v-if="school.bestSchoolOfferings.includes('other')"
            />
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot create an account with the given credentials. Please contact administrator.
          </div>

          <b-form-group label-class="form-label">
            <b-form-checkbox v-model="termsAndConditions" name="check-button">
              <span style="font-weight: initial" class="text-black">
                I understand and accept the
                <!-- <a href="https://jointransition.com/terms" target="_blank" class="text-primary">terms of conditions</a>. -->
                <a href="#" @click.prevent="showTOSModal = true" class="text-primary">terms of conditions</a>.
              </span>
            </b-form-checkbox>
          </b-form-group>

          <div class="form-group">
            <b-btn
              type="submit"
              variant="primary"
              block
              :disabled="isFormLoading || !termsAndConditions"
              class="btn-normal"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Submit Interest</span>
            </b-btn>
          </div>
        </b-form>

        <div slot="footer" class="text-center text-black-50">
          <div class="d-flex justify-content-center mt-0">
            Already have an account?
            <a class="ml-1">
              <router-link :to="{ name: 'login' }" style="text-decoration: underline"> Login</router-link></a
            >
          </div>
        </div>
      </card>
    </div>

    <SchoolTOSModal :showModal.sync="showTOSModal" />
  </div>
</template>


<script>
import axios from 'axios';
import { map } from 'lodash';
import Card from './Card.vue';
import Page from '@/components/Page';
import { countries } from 'countries-list';
import { mapGetters, mapActions } from 'vuex';
import { titleize } from '../../common/utils';
import { SCHOOL_OFFERINGS } from '@/common/constants';
import logo from '@/assets/images/logos/logo_blue.png';
import { email, required } from 'vuelidate/lib/validators';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';
import SchoolTOSModal from '@/components/Common/SchoolTOSModal.vue';
import { FmvAvatar } from 'fmv-avatar';

export default {
  name: 'SchoolSignUp',
  components: { Card, ImageUploader, FmvAvatar, PageSeparator, SchoolTOSModal },
  extends: Page,
  layout: 'blank',

  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
  },

  data() {
    return {
      title: 'School Interest',
      user: {
        email: '',
        firstName: '',
        lastName: '',
      },
      school: {
        name: '',
        email: '',
        logo_url: '',
        small_logo_url: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        phone: '',
        websiteUrl: '',
        country: 'United States',
        bestSchoolOfferings: [],
        otherOfferings: null,
        fromClassTuition: null,
        toClassTuition: null,
        passRatio: null,
        avgProgramWeeks: null,
        universities: [],
        interested_best_cities: [],
        studentsTrained: null,
        programs: [],
      },
      isLoading: false,
      countryOptions: [],
      universitiesOptions: [],
      highSchoolOptions: [],
      stateOptions: [],
      isUploading: { logo: false, smallLogo: false },
      selectedUploadFile: { logo: null, smallLogo: null },
      uploadPercent: { logo: 0, smallLogo: 0 },
      uploadCancelTokenSource: { logo: null, smallLogo: null },
      termsAndConditions: false,
      error: false,
      BestSchoolOfferingsOptions: SCHOOL_OFFERINGS,
      windowWidth: window.innerWidth,
      organization: {},
      allPrograms: [],
      areProgramsLoading: false,
      isImageType: true,
      showTOSModal: false,
    };
  },

  validations() {
    return {
      user: {
        email: { required, email },
        firstName: { required },
        lastName: { required },
      },
      school: {
        name: { required },
        logo_url: {
          required,
        },
        address: { required },
        zipcode: { required },
        city: { required },
        state: { required },
        country: { required },
        universities: {},
        programs: {},
      },
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    errorState() {
      return this.error ? false : undefined;
    },
    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some((val) => val);
    },
    isMobileScreen() {
      return this.windowWidth <= 776;
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getSchoolSignupImageUploadPresignedUrl', 'schoolSignUp']),
    ...mapActions('uni', ['getAllUniversitiesByState']),
    ...mapActions('location', ['getAllHighSchoolsByState']),
    ...mapActions('organization', ['getOrgFromSlug']),
    ...mapActions('program', ['getAllPublicPrograms']),

    setImage(file, fileType, uploadType = 'images') {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType);
    },

    selectUniversity(id) {
      if (this.school.universities.includes(id)) {
        const index = this.school.universities.indexOf(id);
        if (index > -1) {
          this.school.universities.splice(index, 1);
        }
      } else {
        this.school.universities.push(id);
      }
    },
    selectHighSchool(id) {
      if (this.school.interested_best_cities.includes(id)) {
        const index = this.school.interested_best_cities.indexOf(id);
        if (index > -1) {
          this.school.interested_best_cities.splice(index, 1);
        }
      } else {
        this.school.interested_best_cities.push(id);
      }
    },
    async fetchOrganization() {
      if (this.$route.query.organization) {
        const response = await this.getOrgFromSlug({
          slug: this.$route.query.organization,
        });
        this.organization = response.data;
      }
    },
    setState() {
      this.fetchUniversities();
      this.fetchHighSchoolDistricts();
    },
    async fetchUniversities() {
      if (this.school.state && !this.$route.query.organization) {
        const response = await this.getAllUniversitiesByState({
          ...(this.school.state && { state: this.school.state }),
        });
        this.universitiesOptions = response.data;
      } else {
        this.universitiesOptions = [];
      }
    },
    async fetchHighSchoolDistricts() {
      if (this.school.state && !this.$route.query.organization) {
        const response = await this.getAllHighSchoolsByState({
          ...(this.school.state && { state__name: this.school.state }),
        });
        this.highSchoolOptions = response.data;
      } else {
        this.highSchoolOptions = [];
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            school_info: {
              name: this.school.name,
              email: this.school.email,
              logo_url: this.school.logo_url,
              small_logo_url: this.school.small_logo_url,
              address: this.school.address,
              zipcode: this.school.zipcode,
              phone: this.school.phone,
              website_url: this.school.websiteUrl,
              city: this.school.city,
              state: this.school.state,
              country: this.school.country,
              best_school_offerings: this.school.bestSchoolOfferings,
              other_offerings: this.school.otherOfferings,
              from_class_tuition: this.school.fromClassTuition,
              to_class_tuition: this.school.toClassTuition,
              pass_ratio: this.school.passRatio,
              avg_program_weeks: this.school.avgProgramWeeks,
              trained_students: this.school.studentsTrained,
              programs: this.school.programs,
              owner: {
                email: this.user.email,
                first_name: titleize(this.user.firstName),
                last_name: titleize(this.user.lastName),
              },
            },
            interested_universites: this.$route.query.organization ? [] : this.school.universities,
            interested_best_cities: this.$route.query.organization ? [] : this.school.interested_best_cities,
            interest_from: this.$route.query.organization ? 'organization' : 'signup_request',
            ...(this.$route.query.organization && {
              interested_organizations: {
                org_slug: this.$route.query.organization,
              },
            }),
          };

          await this.schoolSignUp(data);
          this.$router.push({
            name: 'verify-school-signup',
            params: { submission: 'success' },
          });
        } catch (_e) {
          this.error = true;
          this.$router.push({
            name: 'verify-school-signup',
            params: { submission: 'failed' },
          });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images') {
      if (!this.selectedUploadFile[fileType]) return;
      this.isUploading[fileType] = true;

      if (this.selectedUploadFile[fileType].type.split('/')[0] !== 'image') {
        this.makeToast({ variant: 'danger', msg: 'Please upload correct file format, only image files are accepted.' });
        this.isImageType = false;
        this.selectedUploadFile[fileType] = null;
      } else {
        this.isImageType = true;
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

        try {
          const urlResp = await this.getSchoolSignupImageUploadPresignedUrl({
            file_name: this.selectedUploadFile[fileType].name,
            content_type: this.selectedUploadFile[fileType].type,
            upload_type: uploadType,
          });
          await this.uploadToPresignedUrl({
            url: urlResp.upload_url,
            file: this.selectedUploadFile[fileType],
            config: {
              onUploadProgress: function (progressEvent) {
                this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              }.bind(this),
              cancelToken: this.uploadCancelTokenSource[fileType].token,
            },
          });
          this.school.logo_url = urlResp.upload_url.split('?')[0];
          this.school.small_logo_url = urlResp.upload_url.split('?')[0];
        } catch (error) {
          this.selectedUploadFile[fileType] = null;
        }

        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
      }
      this.isUploading[fileType] = false;
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPublicPrograms();
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, (state) => ({
          value: state.name,
          text: state.name,
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, (country) => ({
          value: country.name,
          text: country.name,
        }))
      );
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }

    this.fillInOptions();
    this.fetchOrganization();
    this.fetchPrograms();
    if (this.$route.query.state) {
      this.school.state = this.$route.query.state;
      this.setState();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
.sch-sign-header-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px !important;
}

.sch-sign-header-subtitle {
  font-size: 0.9rem !important;
}
</style>
