<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div class="row mt-5 mb-5" v-if="entity" :class="!entity.logo_url ? 'd-flex justify-content-center' : ''">
        <div
          v-if="entity.logo_url"
          class="d-flex justify-content-center col-6 align-items-center border-right border-secondary py-2"
        >
          <div>
            <b-img :src="entity.logo_url" width="120" :alt="entity.name" />
          </div>
        </div>

        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="logo" width="150" :alt="brand" />
          </div>
        </div>
      </div>
      <div class="text-center mt-5 mb-5" v-else>
        <b-img :src="logo" width="200" :alt="brand" />
      </div>

      <card
        title="Create Employer Account"
        subtitle="Register to view candidates, offer sponsorships, post jobs, request to participate in hiring/career events, and more!"
        header-class="text-center"
        class="navbar-shadow"
        title-class="emp-sign-header-title"
        subtitle-class="emp-sign-header-subtitle"
      >
        <slot />

        <b-form @submit.prevent="onSubmit">
          <page-separator title="Your Information" />

          <b-form-group :label="$t('Photo')" label-for="avatar" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center">
              <user-avatar
                slot="aside"
                size="lg"
                :isLoading="isUploading.avatar"
                :user="user"
                url-field="avatarUrl"
                first-name-field="firstName"
                last-name-field="lastName"
              >
              </user-avatar>

              <image-uploader
                @image="e => setImage(e, 'avatar', 'images')"
                :isUploading="isUploading.avatar"
                :isRequired="false"
                :placeholder="$t('Choose photo')"
              />
            </b-media>
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="firstname"
                :placeholder="$t('userMsgs.firstName')"
                v-model.trim="user.firstName"
                :state="!$v.user.firstName.required && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="lastname"
                :placeholder="$t('userMsgs.lastName')"
                v-model.trim="user.lastName"
                :state="!$v.user.lastName.required && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="user.email"
              :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('authMsgs.newPassword')"
              label-for="npass"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="npass"
                name="password"
                placeholder="Password"
                v-model="user.password"
                :required="!!user.repeatPassword"
                :type="fieldTypes.password"
                autocomplete="off"
                @focus="handlePassType"
                @blur="handlePassType"
                :state="
                  (!$v.user.password.required || !$v.user.password.isCorrectFormat) && $v.user.password.$dirty
                    ? false
                    : null
                "
              />
              <b-form-invalid-feedback v-if="!$v.user.password.required"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('profileMsgs.repeatPassword')"
              label-for="repeatPassword"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="repeatPassword"
                name="repeatPassword"
                :placeholder="$t('profileMsgs.repeatPassword')"
                v-model="user.repeatPassword"
                :required="!!user.password"
                :type="fieldTypes.repeatPassword"
                autocomplete="off"
                @focus="handlePassType"
                @blur="handlePassType"
                :state="
                  (!$v.user.repeatPassword.required || !$v.user.repeatPassword.isCorrectFormat) &&
                  $v.user.repeatPassword.$dirty
                    ? false
                    : null
                "
              />
              <b-form-invalid-feedback v-if="!$v.user.repeatPassword.required"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else>{{
                $t(`passValidationMsgs.${repPassErrorCode}`)
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <page-separator title="Company Information" />

          <b-form-group label="Logo" label-for="logo" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isUploading.logo">...</span>
                <b-img :src="employer.logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="employer.logo_url" />
                <md-icon v-else>business</md-icon>
              </fmv-avatar>

              <image-uploader
                @image="e => setImage(e, 'logo')"
                :isUploading="isUploading.logo"
                :isRequired="!$v.employer.logo_url.required"
                placeholder="Select logo image to upload."
              />
            </b-media>
          </b-form-group>

          <b-form-group label="Business Name" label-for="name" label-class="form-label">
            <b-form-input
              id="name"
              placeholder="Enter Business Name"
              v-model="employer.name"
              :state="!$v.employer.name.required && $v.employer.name.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.employer.name.required && $v.employer.name.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group :label="$t('employerMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="employer.phone"
              v-mask="'(###) ### - ####'"
              :state="
                (!$v.employer.phone.required || !$v.employer.phone.isCorrectFormat) && $v.employer.phone.$dirty
                  ? false
                  : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.employer.phone.required">
              This field is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Website" label-for="website" label-class="form-label">
            <b-form-input
              id="website"
              placeholder="Enter company's website url."
              v-model="employer.website_url"
              :state="!$v.employer.website_url.url && $v.employer.website_url.$dirty ? false : null"
            />
            <b-form-invalid-feedback
              >Not a valid link. Please make sure to enter a valid link e.g.
              https://www.example.com.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Company Type & Size Fields -->

          <b-form-group label="Company Size" label-for="company_size" label-class="form-label">
            <v-select
              id="company_size"
              class="form-control v-select-custom"
              label="text"
              v-model="employer.company_size"
              :reduce="item => item.value"
              placeholder="Select Company Size"
              :options="companySizeOptions"
              :class="
                !$v.employer.company_size.required && $v.employer.company_size.$dirty ? 'form-control is-invalid' : ''
              "
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="!$v.employer.company_size.required && $v.employer.company_size.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Company Type" label-for="company_type" label-class="form-label">
            <v-select
              id="company_type"
              class="form-control v-select-custom"
              label="text"
              v-model="employer.company_type"
              :reduce="item => item.value"
              placeholder="Select Company Type."
              :options="companyTypeOptions"
              :class="
                !$v.employer.company_type.required && $v.employer.company_type.$dirty ? 'form-control is-invalid' : ''
              "
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="!$v.employer.company_type.required && $v.employer.company_type.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Short Description" label-for="short_bio" label-class="form-label">
            <b-form-textarea
              id="short_bio"
              placeholder="Enter company's short/summarized bio."
              :rows="5"
              v-model="employer.short_bio"
              :state="!$v.employer.short_bio.required && $v.employer.short_bio.$dirty ? false : null"
            />
            <b-form-invalid-feedback
              :state="!$v.employer.short_bio.required && $v.employer.short_bio.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="About" label-for="bio" label-class="form-label">
            <editor
              :init="{
                height: 400,
                menubar: 'edit view insert format tools table help',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
              }"
              v-model="employer.bio"
              placeholder="Enter company's bio."
              :state="!$v.employer.bio.required && $v.employer.bio.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.employer.bio.required && $v.employer.bio.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Address Fields -->
          <b-form-group label="Street Address" label-for="address" label-class="form-label">
            <b-form-input
              id="address"
              placeholder="Street Address"
              v-model="employer.address"
              :state="!$v.employer.address.required && $v.employer.address.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.employer.address.required && $v.employer.address.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('addressMsgs.zipcode')"
              label-for="zipcode"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="zipcode"
                :placeholder="$t('addressMsgs.zipcode')"
                v-model="employer.zipcode"
                :state="!$v.employer.zipcode.required && $v.employer.zipcode.$dirty ? false : null"
              />
              <b-form-invalid-feedback
                :state="!$v.employer.zipcode.required && $v.employer.zipcode.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
              <b-form-input
                id="city"
                :placeholder="$t('addressMsgs.city')"
                v-model="employer.city"
                :state="!$v.employer.city.required && $v.employer.city.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="row">
            <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
              <!-- <b-form-input
                id="state"
                :placeholder="$t('addressMsgs.state')"
                v-model="employer.state"
                :state="!$v.employer.state.required && $v.employer.state.$dirty ? false : null"
              /> -->

              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="employer.state"
                :reduce="state => state.value"
                :placeholder="$t('addressMsgs.state')"
                :options="stateOptions"
                :state="!$v.employer.state.required && $v.employer.state.$dirty ? false : null"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!employer.state" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback :state="!$v.employer.state.required && $v.employer.state.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="$t('addressMsgs.country')"
              label-for="country"
              label-class="form-label"
              class="col-md-6"
            >
              <v-select
                id="country"
                class="form-control v-select-custom"
                label="text"
                v-model="employer.country"
                :reduce="country => country.value"
                :placeholder="$t('addressMsgs.countryPlaceholder')"
                :options="countryOptions"
                :class="!$v.employer.country.required && $v.employer.country.$dirty ? 'form-control is-invalid' : ''"
                disabled
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.employer.country.required && $v.employer.country.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <b-form-group
            label="Company Banner"
            label-for="profileBgImage"
            label-class="form-label"
            class="row-align-items-center"
          >
            <b-media class="align-items-center" vertical-align="center">
              <image-uploader
                @image="e => setImage(e, 'profileBgImage', 'images', 'profile_bg_img_url')"
                :isUploading="isUploading.profileBgImage"
                :isRequired="false"
                placeholder="Select company's banner image."
              />
            </b-media>
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot create an account with the given credentials. Please contact administrator.
          </div>

          <b-form-group label-class="form-label">
            <b-form-checkbox v-model="termsAndConditions" name="check-button">
              <span style="font-weight: initial" class="text-black">
                I understand and accept the
                <a href="https://jointransition.com/terms" target="_blank" class="text-primary">terms of conditions</a>.
              </span>
            </b-form-checkbox>
          </b-form-group>

          <div class="form-group">
            <b-btn
              type="submit"
              variant="primary"
              block
              :disabled="isFormLoading || !termsAndConditions"
              class="btn-normal"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Get Started</span>
            </b-btn>
          </div>

          <!-- Text Place (Use if needed!) -->
          <!-- <div class="text-center">
            <component
              :is="linkComponent"
              :to="routeTo('forgot-password')"
              class="text-black-70"
              style="text-decoration: underline"
              v-text="`${$t('authMsgs.forgotPass')}?`"
            />
          </div> -->
        </b-form>

        <div slot="footer" class="text-center text-black-50">
          <div class="d-flex justify-content-center mt-0">
            Already have an account?
            <a class="ml-1">
              <router-link :to="{ name: 'login' }" style="text-decoration: underline"> Login</router-link></a
            >
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import { countries } from 'countries-list';
import { mapGetters, mapActions } from 'vuex';
import { email, required, url } from 'vuelidate/lib/validators';
import Card from '@/components/Ui/Card.vue';
import { map } from 'lodash';

import Page from '@/components/Page';
import logo from '@/assets/images/logos/logo_blue.png';
import { PASSWORD_ALL_NUMERIC_REGEX, PASSWORD_VALIDATIONS, PHONE_FORMAT_REGEX } from '../../common/constants';
import { titleize } from '../../common/utils';
import UserAvatar from '../../components/User/UserAvatar.vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'EmployerSignUp',
  components: { Card, UserAvatar, Editor, ImageUploader, FmvAvatar, PageSeparator },
  extends: Page,
  layout: 'blank',

  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
    linkComponent: {
      type: [String, Object],
      default: () => 'b-link'
    }
  },

  data() {
    return {
      title: 'Employer Sign Up',
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        avatarUrl: ''
      },
      employer: {
        name: '',
        logo_url: '',
        profile_bg_img_url: '',
        phone: '',
        website_url: '',
        company_size: '',
        company_type: '',
        short_bio: '',
        bio: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States'
      },
      entity: null,
      countryOptions: [],
      stateOptions: [],

      companySizeOptions: [
        { value: '0-1', text: '0-1 employees' },
        { value: '2-10', text: '2-10 employees' },
        { value: '11-50', text: '11-50 employees' },
        { value: '51-200', text: '51-200 employees' },
        { value: '201-500', text: '201-500 employees' },
        { value: '501-1000', text: '501-1,000 employees' },
        { value: '1001-5000', text: '1,001-5,000 employees' },
        { value: '5001-10000', text: '5,001-10,000 employees' },
        { value: '10001+', text: '10,001+ employees' }
      ],
      companyTypeOptions: [
        { value: 'ambulatory_surgical_center', text: 'Ambulatory Surgical Center' },
        { value: 'animal_clinic', text: 'Animal Clinic' },
        { value: 'assisted_living_facility', text: 'Assisted Living Facility' },
        { value: 'biotech_company', text: 'Biotech Company' },
        { value: 'blood_bank', text: 'Blood Bank' },
        { value: 'chiropractic_clinic', text: 'Chiropractic Clinic' },
        { value: 'counseling_service', text: 'Counseling Service' },
        { value: 'crime_scene_investigation_unit', text: 'Crime Scene Investigation Unit' },
        { value: 'dental_practice', text: 'Dental Practice' },
        { value: 'diagnostic_lab', text: 'Diagnostic Lab' },
        { value: 'dietetics_practice', text: 'Dietetics Practice' },
        { value: 'dna_testing_facility', text: 'DNA Testing Facility' },
        { value: 'drug_distribution_company', text: 'Drug Distribution Company' },
        { value: 'emergency_animal_hospital', text: 'Emergency Animal Hospital' },
        { value: 'family_health_clinic', text: 'Family Health Clinic' },
        { value: 'forensic_consultancy', text: 'Forensic Consultancy' },
        { value: 'forensic_laboratory', text: 'Forensic Laboratory' },
        { value: 'general_dentistry_practice', text: 'General Dentistry Practice' },
        { value: 'general_hospital', text: 'General Hospital' },
        { value: 'gym_with_physiotherapy', text: 'Gym with Physiotherapy' },
        { value: 'health_and_wellness_clinic', text: 'Health and Wellness Clinic' },
        { value: 'healthcare_it_company', text: 'Healthcare IT Company' },
        { value: 'health_insurance_company', text: 'Health Insurance Company' },
        { value: 'holistic_wellness_center', text: 'Holistic Wellness Center' },
        { value: 'home_health_agency', text: 'Home Health Agency' },
        { value: 'hospice_care_provider', text: 'Hospice Care Provider' },
        { value: 'imaging_center', text: 'Imaging Center' },
        { value: 'long_term_acute_care_hospital', text: 'Long-Term Acute Care Hospital' },
        { value: 'meal_planning_service', text: 'Meal Planning Service' },
        { value: 'medical_billing_company', text: 'Medical Billing Company' },
        { value: 'medical_research_lab', text: 'Medical Research Lab' },
        { value: 'medical_supply_company', text: 'Medical Supply Company' },
        { value: 'medical_transportation', text: 'Medical Transportation' },
        { value: 'memory_care_facility', text: 'Memory Care Facility' },
        { value: 'mental_health_clinic', text: 'Mental Health Clinic' },
        { value: 'mobile_imaging_service', text: 'Mobile Imaging Service' },
        { value: 'multidisciplinary_clinic', text: 'Multidisciplinary Clinic including Chiropractic Care' },
        { value: 'nutrition_consulting_firm', text: 'Nutrition Consulting Firm' },
        { value: 'optometric_clinic', text: 'Optometric Clinic' },
        { value: 'oral_surgery_center', text: 'Oral Surgery Center' },
        { value: 'orthodontic_practice', text: 'Orthodontic Practice' },
        { value: 'outpatient_clinic', text: 'Outpatient Clinic' },
        { value: 'pathology_lab', text: 'Pathology Lab' },
        { value: 'pediatric_dentistry_practice', text: 'Pediatric Dentistry Practice' },
        { value: 'periodontic_practice', text: 'Periodontic Practice' },
        { value: 'pet_boarding_facility', text: 'Pet Boarding Facility' },
        { value: 'pharmacy', text: 'Pharmacy' },
        { value: 'pharmaceutical_manufacturer', text: 'Pharmaceutical Manufacturer' },
        { value: 'pharmaceutical_research_company', text: 'Pharmaceutical Research Company' },
        { value: 'physical_therapy_center', text: 'Physical Therapy Center' },
        { value: 'psychiatric_hospital', text: 'Psychiatric Hospital' },
        { value: 'radiology_department', text: 'Radiology Department in a Hospital' },
        { value: 'rehabilitation_center', text: 'Rehabilitation Center' },
        { value: 'respite_care', text: 'Respite Care' },
        { value: 'skilled_nursing_facility', text: 'Skilled Nursing Facility' },
        { value: 'specialty_hospital', text: 'Specialty Hospital' },
        { value: 'substance_abuse_treatment_center', text: 'Substance Abuse Treatment Center' },
        { value: 'teleradiology_company', text: 'Teleradiology Company' },
        { value: 'urgent_care_center', text: 'Urgent Care Center' },
        { value: 'veterinary_hospital', text: 'Veterinary Hospital' },
        { value: 'veterinary_laboratory', text: 'Veterinary Laboratory' },
        { value: 'vision_care_center', text: 'Vision Care Center' },
        { value: 'weight_loss_center', text: 'Weight Loss Center' },
        { value: 'wellness_center_with_chiropractic_services', text: 'Wellness Center with Chiropractic Services' },
        { value: 'other', text: 'Other' }
      ],

      errorStates: { password: null, phone: null },
      passErrorCode: null,
      repPassErrorCode: null,

      error: false,
      isLoading: false,
      fieldTypes: { password: 'text', repeatPassword: 'text' },

      isUploading: { avatar: false, logo: false, profileBgImage: false },
      selectedUploadFile: { avatar: null, logo: null, profileBgImage: null },
      uploadPercent: { avatar: 0, logo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { avatar: null, logo: null, profileBgImage: null },

      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      termsAndConditions: false
    };
  },

  validations() {
    return {
      user: {
        avatarUrl: {},
        firstName: { required },
        lastName: { required },
        email: { required, email },
        password: {
          required,
          isCorrectFormat(value) {
            if (value.length < 5) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
              return false;
            } else if (PASSWORD_ALL_NUMERIC_REGEX.test(value)) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
              return false;
            } else if (value !== this.user.repeatPassword) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          }
        },
        repeatPassword: {
          required,
          isCorrectFormat() {
            if (this.user.password !== this.user.repeatPassword) {
              this.repPassErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          }
        }
      },

      employer: {
        name: { required },
        logo_url: { required },
        profile_bg_img_url: {},
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          }
        },
        website_url: { url },
        company_size: { required },
        company_type: { required },
        short_bio: { required },
        bio: { required },
        primary_color: {},
        secondary_color: {},
        address: { required },
        zipcode: { required },
        city: { required },
        state: { required },
        country: { required }
      }
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    errorState() {
      return this.error ? false : undefined;
    },

    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    }
  },

  mounted() {
    if (this.$route.query.referral_id) {
      this.getEntity(this.$route.query.referral_id);
    } else {
      this.getEntity(this.$route.query.org_refer_id);
    }
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
    this.fillInOptions();
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('employer', ['signupEmployer', 'getEmployerUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getSchoolFromReffId']),
    ...mapActions('organization', ['getOrgFromReffId']),
    setImage(file, image, uploadType = 'images', profileUrlField = 'logo_url') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, uploadType, profileUrlField);
    },
    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },
    getErrors(key) {
      if (this.errors && this.errors[key] !== undefined) {
        return this.errors[key];
      }
    },

    async getEntity(id) {
      try {
        let res;
        if (this.$route.query.referral_id) {
          res = await this.getSchoolFromReffId({
            unique_id: id
          });
        } else {
          res = await this.getOrgFromReffId({
            org_refer_id: id
          });
        }
        this.entity = res.data;
      } catch (e) {
        // TODO: error
      }
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            ...this.employer,
            ...(this.$route.query.referral_id && { referral_id: this.$route.query.referral_id }),
            ...(this.$route.query.org_refer_id && { org_refer_id: this.$route.query.org_refer_id }),
            main_admin: {
              email: this.user.email,
              first_name: titleize(this.user.firstName),
              last_name: titleize(this.user.lastName),
              password: this.user.password,
              phone: this.employer.phone,
              avatar_url: this.user.avatarUrl
            }
          };

          const form = {
            email: this.user.email,
            password: this.user.password
          };

          await this.signupEmployer(data);
          await this.$store.dispatch('auth/login', form);
          if (this.$route.query.referral_id) {
            // this.$router.push({ name: 'login' });
            this.$router.push({
              name: 'emp-school-profile',
              params: {
                id: this.entity.id
              }
            });
          } else {
            this.$router.push({
              name: 'employer-home'
            });
          }
        } catch (_e) {
          this.error = true;
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'logo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getEmployerUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (fileType === 'avatar') this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        else this.employer[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    }
  }
};
</script>

<style lang="scss">
.emp-sign-header-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px !important;
}

.emp-sign-header-subtitle {
  font-size: 0.9rem !important;
}
</style>
