<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div :class="isPublic ? 'col-sm-8 col-md-6 col-lg-6 mx-auto' : containerClass" style="min-width: 300px">
      <div class="text-center mt-5 mb-5" v-if="isPublic">
        <b-img :src="get(inquiry, 'school.logo_url')" width="120" :alt="brand" />
      </div>

      <div class="" v-if="!isPublic">
        <div class="d-flex justify-content-center mt-2 text-center">
          <h3 class="mb-0 font-weight-bolder" v-text="`Post Sponsorship Spotlight`" />
        </div>

        <div class="mx-3 mb-4 mt-2 text-center">
          <span style="font-size: 0.9125rem">
            Are you eager to mark your presence in the healthcare industry and want local healthcare companies to
            provide financial assistance for your training journey? Post your spotlight to let Healthcare Companies
            sponsor you.
          </span>
        </div>
        <div class="card" style="height: 95%">
          <div class="card-header bg-light d-flex">
            <div>
              <h5 class="my-0">Student Information</h5>
            </div>
            <div class="ml-auto">
              <i class="fas fa-user-edit icon-16pt mr-1"></i>
              <router-link target="_blank" to="/student/my-profile">Edit Profile</router-link>
            </div>
          </div>
          <div class="card-body d-flex flex-column justify-content-center p-3">
            <div>
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="70%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="60%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="50%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="40%"></b-skeleton>
                  </p>
                </template>

                <div class="icon-align mb-2">
                  <i class="fas fa-user icon-16pt mr-1"></i><b class="mr-1 form-label">Name:</b
                  >{{ get(getLoggedInUser, 'first_name') }}
                  {{ get(getLoggedInUser, 'last_name') }}
                </div>

                <div class="icon-align mb-2">
                  <i class="fas fa-envelope icon-16pt mr-1"></i><b class="mr-1 form-label">Email:</b
                  >{{ get(getLoggedInUser, 'email') }}
                </div>

                <div class="icon-align mb-2">
                  <i class="fas fa-phone icon-16pt mr-1"></i><b class="mr-1 form-label">Phone:</b
                  >{{ get(getLoggedInUser, 'phone') }}
                </div>

                <div
                  v-if="
                    get(student, 'address') &&
                      get(student, 'city') &&
                      get(student, 'state') &&
                      get(student, 'zipcode') &&
                      get(student, 'country')
                  "
                  class="icon-align"
                >
                  <i class="fas fa-map-marker-alt icon-16pt mr-1"></i><b class="mr-1 form-label">Location:</b
                  >{{ get(student, 'address') }}, {{ get(student, 'city') }}, {{ get(student, 'state') }}
                  {{ get(student, 'zipcode') }},
                  {{ get(student, 'country') }}
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
      </div>

      <card
        :title="isPublic ? 'Post Sponsorship Spotlight' : ''"
        :subtitle="
          isPublic
            ? `Are you eager to mark your presence in the healthcare industry and want local healthcare companies to
                provide financial assistance for your training journey? Post your spotlight to let Healthcare Companies
                sponsor you.`
            : ''
        "
        :header-class="isPublic ? 'text-center' : ''"
        class="navbar-shadow story-card-body"
        :title-class="isPublic ? 'emp-sign-header-title' : ''"
        :subtitle-class="isPublic ? 'emp-sign-header-subtitle' : ''"
      >
        <div v-if="!isPublic" class="card-header bg-light d-flex">
          <div>
            <h5 class="my-0">Student Information</h5>
          </div>
        </div>

        <b-form class="p-3" @submit.prevent="onSubmit">
          <page-separator v-if="isPublic" title="Your Information" />

          <b-form-group :label="$t('Photo')" label-for="avatar" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center">
              <user-avatar
                slot="aside"
                size="lg"
                :isLoading="isUploading.avatar"
                :user="user"
                url-field="avatarUrl"
                first-name-field="firstName"
                last-name-field="lastName"
              >
              </user-avatar>

              <image-uploader
                @image="e => setImage(e, 'avatar', 'images')"
                :isUploading="isUploading.avatar"
                :isRequired="false"
                :placeholder="$t('Choose photo')"
              />
            </b-media>
          </b-form-group>

          <div v-if="isPublic" class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="firstname"
                :placeholder="$t('userMsgs.firstName')"
                v-model.trim="user.firstName"
                :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="lastname"
                :placeholder="$t('userMsgs.lastName')"
                v-model.trim="user.lastName"
                :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group v-if="isPublic" :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="user.email"
              :disabled="isPublic"
              :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="isPublic" :label="$t('employerMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="user.phone"
              v-mask="'(###) ### - ####'"
              :state="
                (!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.user.phone.required"> This field is required. </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="isPublic" label="Street Address" label-for="address" label-class="form-label">
            <b-form-input
              id="address"
              placeholder="Street Address"
              v-model="user.address"
              :state="!$v.user.address.required && $v.user.address.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.user.address.required && $v.user.address.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div v-if="isPublic" class="row">
            <b-form-group
              :label="$t('addressMsgs.zipcode')"
              label-for="zipcode"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="zipcode"
                :placeholder="$t('addressMsgs.zipcode')"
                v-model="user.zipcode"
                :state="!$v.user.zipcode.required && $v.user.zipcode.$dirty ? false : null"
              />
              <b-form-invalid-feedback :state="!$v.user.zipcode.required && $v.user.zipcode.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
              <b-form-input
                id="city"
                :placeholder="$t('addressMsgs.city')"
                v-model="user.city"
                :state="!$v.user.city.required && $v.user.city.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div v-if="isPublic" class="row">
            <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="user.state"
                :reduce="state => state.value"
                :placeholder="$t('addressMsgs.state')"
                :options="stateOptions"
                :state="!$v.user.state.required && $v.user.state.$dirty ? false : null"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!user.state" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback :state="!$v.user.state.required && $v.user.state.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="$t('addressMsgs.country')"
              label-for="country"
              label-class="form-label"
              class="col-md-6"
            >
              <v-select
                id="country"
                class="form-control v-select-custom"
                label="text"
                v-model="user.country"
                :reduce="country => country.value"
                :placeholder="$t('addressMsgs.countryPlaceholder')"
                :options="countryOptions"
                :class="!$v.user.country.required && $v.user.country.$dirty ? 'form-control is-invalid' : ''"
                disabled
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <b-form-invalid-feedback :state="!$v.user.country.required && $v.user.country.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <b-form-group label="Programs" label-for="programs" label-class="form-label">
            <v-select
              id="programs"
              class="form-control v-select-custom"
              label="title"
              v-model="user.programs"
              :reduce="item => item.id"
              placeholder="All Programs"
              :options="allPrograms"
              multiple
              :state="!$v.user.programs.required && $v.user.programs.$dirty ? false : null"
            >
            </v-select>
            <b-form-invalid-feedback :state="!$v.user.programs.required && $v.user.programs.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            label="What are your future goals as a healthcare professional?"
            label-for="future_goals"
            label-class="form-label"
          >
            <editor
              :init="{
                height: 400,
                menubar: '',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic underline blockquote backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
              }"
              v-model="user.futureGoals"
              placeholder="Write your future goals."
              :state="!$v.user.futureGoals.required && $v.user.futureGoals.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.user.futureGoals.required && $v.user.futureGoals.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            label="Why do you deserve this opportunity?"
            label-for="personal_story"
            label-class="form-label"
          >
            <editor
              :init="{
                height: 400,
                menubar: '',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic underline blockquote backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
              }"
              v-model="user.personalStory"
              placeholder="Write your future goals."
              :state="!$v.user.personalStory.required && $v.user.personalStory.$dirty ? false : null"
            />
            <b-form-invalid-feedback
              :state="!$v.user.personalStory.required && $v.user.personalStory.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="" label-for="allow_work_contract" label-class="form-label">
            <b-form-checkbox
              id="allow_work_contract"
              v-model="user.workContract"
              name="allow_work_contract"
              :value="true"
              :unchecked-value="false"
              :state="!$v.user.workContract.required && $v.user.workContract.$dirty ? false : null"
            >
              If chosen for a tuition sponsorship, you agree to work with a healthcare company for a determined number
              of months.
            </b-form-checkbox>
            <b-form-invalid-feedback
              :state="!$v.user.workContract.required && $v.user.workContract.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div class="form-group">
            <b-btn
              type="submit"
              variant="primary"
              block
              :disabled="isFormLoading || !user.workContract"
              class="btn-normal"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Submit</span>
            </b-btn>
          </div>
        </b-form>
      </card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Editor from '@tinymce/tinymce-vue';

import { mapGetters, mapActions } from 'vuex';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import Card from '@/components/Ui/Card.vue';
import { get, map } from 'lodash';

import Page from '@/components/Page.vue';
import logo from '@/assets/images/logos/logo_blue.png';
import { PHONE_FORMAT_REGEX } from '../../common/constants';
import { titleize } from '../../common/utils';
import { countries } from 'countries-list';
import UserAvatar from '../../components/User/UserAvatar.vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: '',
  components: { Card, UserAvatar, Editor, ImageUploader, PageSeparator },
  extends: Page,

  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
    linkComponent: {
      type: [String, Object],
      default: () => 'b-link'
    }
  },

  data() {
    return {
      title: 'Post Sponsorship Spotlight',
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',

        avatarUrl: '',
        logo_url: '',
        story: '',
        programs: [],
        futureGoals: '',
        personalStory: '',
        workContract: false
      },
      areProgramsLoading: false,
      allPrograms: [],
      inquiry: {},
      stateOptions: [],
      countryOptions: [],
      student: {},

      error: false,
      isLoading: false,
      isPublic: false,

      isUploading: { avatar: false, logo: false, profileBgImage: false },
      selectedUploadFile: { avatar: null, logo: null, profileBgImage: null },
      uploadPercent: { avatar: 0, logo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { avatar: null, logo: null, profileBgImage: null },

      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      termsAndConditions: false
    };
  },

  validations() {
    return {
      user: {
        // avatarUrl: {},
        firstName: { required: requiredIf(() => this.isPublic) },
        lastName: { required: requiredIf(() => this.isPublic) },
        email: { required: requiredIf(() => this.isPublic), email },

        phone: {
          required: requiredIf(() => this.isPublic),
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          }
        },

        address: { required },
        zipcode: { required },
        city: { required },
        state: { required },
        country: { required },

        programs: { required },
        futureGoals: { required },
        personalStory: { required },
        workContract: { required }
      }
    };
  },

  computed: {
    ...mapGetters('stories', ['getStoryExists']),
    ...mapGetters('auth', ['getLoggedInUser', 'isLoggedIn']),
    errorState() {
      return this.error ? false : undefined;
    },

    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    }
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('stories', ['createStories', 'getStoriesUploadPresignedUrl', 'getInquiry', 'getStoriesVerification']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getPublicSchoolPrograms']),
    ...mapActions('student', ['getMyStudentProfile']),

    get,
    setImage(file, image, uploadType = 'images') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, uploadType);
    },

    async fetchStudentProfile() {
      const response = await this.getMyStudentProfile(true);

      this.student = response;
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getPublicSchoolPrograms({
        school: this.isPublic ? this.inquiry.school.transition_sch_id : this.getLoggedInUser.linked_entity.id
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            email: this.user.email,
            first_name: titleize(this.user.firstName),
            last_name: titleize(this.user.lastName),

            phone: this.user.phone,
            address: this.user.address,
            city: this.user.city,
            zipcode: this.user.zipcode,
            state: this.user.state,
            country: this.user.country,
            image_url: this.user.avatarUrl,
            future_goals: this.user.futureGoals,
            personal_story: this.user.personalStory,
            allow_work_contract: this.user.workContract,
            programs: this.user.programs,
            ...(this.$route.query.inquiry && { inquiry_id: this.$route.query.inquiry }),
            school: this.isPublic ? this.inquiry.school.transition_sch_id : this.getLoggedInUser.linked_entity.id
          };

          await this.createStories(data);
          if (!this.isPublic) {
            await this.getStoriesVerification();
          }
          this.$router.push({
            name: this.isPublic ? 'verify-sponsorship-spotlight' : 'verify-student-sponsorship-spotlight',
            params: { submission: 'success' }
          });
        } catch (_e) {
          this.error = true;
          this.$router.push({
            name: this.isPublic ? 'verify-sponsorship-spotlight' : 'verify-student-sponsorship-spotlight',
            params: { submission: 'failed' }
          });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getStoriesUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (fileType === 'avatar') this.user.avatarUrl = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    }
  },

  async mounted() {
    this.fillInOptions();

    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
      this.isPublic = true;
    }

    if (this.$route.query.inquiry) {
      const res = await this.getInquiry({ inquiry: this.$route.query.inquiry });

      this.inquiry = res.data;
      this.user.firstName = this.inquiry.first_name;
      this.user.lastName = this.inquiry.last_name;
      this.user.email = this.inquiry.email;
      this.user.phone = this.inquiry.phone;
      this.user.address = this.inquiry.school.address;
      this.user.city = this.inquiry.school.city;
      this.user.zipcode = this.inquiry.school.zipcode;
      this.user.state = this.inquiry.school.state;
      this.user.country = this.inquiry.school.country;
    }

    await this.fetchPrograms();
    const programs = this.allPrograms.map(program => program.id);
    if (this.$route.query.inquiry && programs.includes(this.inquiry.program.transition_program_id)) {
      this.user.programs.push(this.inquiry.program.transition_program_id);
    }

    if (!this.isPublic && this.isLoggedIn) {
      await this.fetchStudentProfile();

      this.user.avatarUrl = this.getLoggedInUser.avatar_url;
      this.user.firstName = this.getLoggedInUser.first_name;
      this.user.lastName = this.getLoggedInUser.last_name;
      this.user.phone = this.getLoggedInUser.phone;
      this.user.email = this.getLoggedInUser.email;

      this.user.address = this.student.address;
      this.user.city = this.student.city;
      this.user.zipcode = this.student.zipcode;
      this.user.state = this.student.state;
      this.user.country = this.student.country;
    }
  }
};
</script>

<style lang="scss" scoped>
.emp-sign-header-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px !important;
}

.emp-sign-header-subtitle {
  font-size: 0.9rem !important;
}

.story-card-body ::v-deep .card-body {
  padding: 0px !important;
}
</style>
