<template>
  <div class="page-section mt-5">
    <div class="container">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="page-section d-flex justify-content-center">
            <div class="card card-block card-stretch card-height col-md-8">
              <div class="card-body text-center">
                <div class="d-flex justify-content-center my-4">
                  <b-skeleton type="avatar" style="font-size: 1.1rem"></b-skeleton>
                </div>
                <div class="d-flex justify-content-center">
                  <b-skeleton width="50%" class="mb-4"></b-skeleton>
                </div>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="75%"></b-skeleton>
                </p>
                <div class="d-flex justify-content-center">
                  <b-skeleton type="button" width="160px"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i
                v-if="$route.params.submission === 'success'"
                class="fas fa-check-circle fa-lg text-success mt-4 mb-4"
                style="font-size: 3rem"
              />
              <i v-else class="fas fa-times-circle fa-lg text-danger mt-4 mb-4" style="font-size: 3rem" />
              <h4>
                {{ $route.params.submission === 'success' ? 'Request Submitted' : 'Request Submission Failed!' }}
              </h4>

              <p class="text-muted" v-if="$route.params.submission === 'success'">
                You have successfully submitted your on-boarding request. We will get back to you soon. In case of any
                concern, please contact administrator.
              </p>
              <p class="text-muted" v-else>
                Your request cannot be submitted. Please try again later or contact the administrator.
              </p>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page';

export default {
  name: 'AdvisorSignupConfirm',
  extends: Page,
  data() {
    return {
      title: 'Request for new Advisor',
      isLoading: true,
    };
  },

  async mounted() {
    if (this.$route.params.submission) this.isLoading = false;
  },
};
</script>

<style>
</style>