<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div class="row mt-5 mb-5" :class="'d-flex justify-content-center'">
        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="get(entity, 'logo_url')" width="150" :alt="get(entity, 'name')" v-if="hasOrgInvite" />
            <b-img src="/images/logos/logo_blue.png" width="150" alt="Transition" v-else />
          </div>
        </div>
      </div>

      <card
        title="Request for new Advisor"
        :subtitle="`Fill the form given below to get yourself approved as an advisor for ${get(
          entity,
          'name',
          'universities'
        )}`"
        header-class="text-center"
        class="navbar-shadow"
      >
        <slot />

        <b-form @submit.prevent="onSubmit">
          <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isImgUploading">...</span>
                <b-img :src="advisor.image" class="img-fluid" width="40" alt="Image" v-else-if="advisor.image" />
                <md-icon v-else>account_circle</md-icon>
              </fmv-avatar>

              <image-uploader
                @image="(e) => setImage(e)"
                :is-uploading="isImgUploading"
                placeholder="Select Image"
                :state="!$v.advisor.image.required && $v.advisor.image.$dirty"
                state-message="This field is required"
              />
            </b-media>
          </b-form-group>

          <div class="row">
            <b-form-group label="First Name" label-for="firstName" label-class="form-label" class="col-md-6">
              <b-form-input
                id="firstName"
                placeholder="Enter First Name"
                v-model="advisor.first_name"
                :state="!$v.advisor.first_name.required && $v.advisor.first_name.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Last Name" label-for="lastName" label-class="form-label" class="col-md-6">
              <b-form-input
                id="lastName"
                placeholder="Enter Last Name"
                v-model="advisor.last_name"
                :state="!$v.advisor.last_name.required && $v.advisor.last_name.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group :label="'Email'" label-for="email" label-class="form-label">
            <b-form-input
              id="name"
              :placeholder="'Email'"
              v-model="advisor.email"
              :state="(!$v.advisor.email.required || !$v.advisor.email.email) && $v.advisor.$dirty ? false : null"
            />

            <b-form-invalid-feedback v-if="!$v.advisor.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Phone" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="advisor.phone"
              v-mask="'(###) ### - ####'"
              :state="!$v.advisor.phone.isCorrectFormat && $v.advisor.phone.$dirty ? false : null"
            />
            <b-form-invalid-feedback>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="About" label-for="description" label-class="form-label">
            <editor
              :init="{
                height: 400,
                menubar: 'edit view insert format tools table help',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
              }"
              v-model="advisor.description"
              placeholder="Enter info & details about your experience."
            />
            <b-form-invalid-feedback :state="!$v.advisor.description.required && $v.advisor.$dirty ? false : null">
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- universities -->

          <b-form-group
            v-if="!hasOrgInvite"
            label="Select All Affiliated Universities"
            label-for="universities"
            label-class="form-label"
          >
            <div class="adv-unis-list">
              <div class="row">
                <div
                  class="mb-2 col-md-6"
                  style="cursor: pointer"
                  v-for="option in universitiesOptions"
                  :key="option.id"
                >
                  <div
                    @click.prevent="selectUniversity(option.id)"
                    style="cursor: pointer; height: 100%"
                    class="card p-3 my-auto"
                  >
                    <b-form-checkbox name="universities" v-model="advisor.universities" :value="option.id">
                      <div class="d-flex" style="cursor: pointer">
                        <img style="width: 40px; height: 40px" class="mr-2" :src="option.small_logo_url" alt="" />
                        <span style="font-size: 16px">
                          {{ option.name }}
                        </span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>

          <!-- Work Experience -->

          <b-form-group label-class="form-label">
            <template #label>
              <div class="d-flex justify-content-between">
                Experience
                <b-btn variant="primary" :disabled="isLoading" @click.prevent="openWorkExpModal()" size="sm">
                  <span>
                    <i class="fas fa-plus align-self-center"></i>
                    <strong class="ml-1">Add Experience</strong>
                  </span>
                </b-btn>
              </div>
            </template>

            <div v-for="(exp, index) in advisor.experience" :key="`adv-exp-${index}`">
              <div class="mb-3 adv-exp-item">
                <div>
                  <h6 class="mb-0 pb-0">{{ exp.title }}</h6>
                  <div>
                    <i class="material-icons icon-16pt mr-1">business</i>
                    <strong>{{ exp.employer }}</strong>
                  </div>
                  <div>
                    {{ formatExpDate(exp.start_date) }} to
                    {{ exp.still_working ? 'Present' : formatExpDate(exp.end_date) }}
                  </div>
                  <div>{{ exp.city }}, {{ exp.state }}</div>
                  <div v-for="(task, index) in exp.work_tasks" :key="`exp-task-${index}`">
                    <i class="material-icons icon-16pt mr-1">check_circle</i>{{ task }}
                  </div>
                </div>
                <div class="adv-exp-actions">
                  <b-btn
                    variant="secondary"
                    :disabled="isLoading"
                    @click.prevent="openWorkExpModal(exp, index)"
                    size="sm"
                  >
                    <span>
                      <i class="fas fa-pen align-self-center"></i>
                      <strong class="ml-1">Edit</strong>
                    </span>
                  </b-btn>
                  <b-btn
                    variant="danger"
                    :disabled="isLoading"
                    @click.prevent="removeExpEduItem(index, 'experience', 'experience')"
                    size="sm"
                    class="ml-1"
                  >
                    <span>
                      <i class="fas fa-trash align-self-center"></i>
                      <strong class="ml-1">Remove</strong>
                    </span>
                  </b-btn>
                </div>
              </div>
            </div>
            <i v-if="!get(advisor, 'experience.length')">There are no work experience added.</i>
          </b-form-group>

          <!-- Education -->

          <b-form-group label-class="form-label">
            <template #label>
              <div class="d-flex justify-content-between">
                Education
                <b-btn variant="primary" :disabled="isLoading" @click.prevent="openEducationModal()" size="sm">
                  <span>
                    <i class="fas fa-plus align-self-center"></i>
                    <strong class="ml-1">Add Education</strong>
                  </span>
                </b-btn>
              </div>
            </template>

            <div v-for="(education, index) in advisor.education" :key="`adv-edu-${index}`">
              <div class="mb-3 adv-exp-item">
                <div>
                  <h6 class="mb-0 pb-0">{{ $t(`resumeEducationTypes.${education.type}`) }}, {{ education.study }}</h6>
                  <div>
                    <i class="material-icons icon-16pt mr-1">account_balance</i>
                    <strong>{{ education.institution }}</strong>
                  </div>
                  <div>
                    {{ formatExpDate(education.start_date) }} to
                    {{ education.still_attending ? 'Present' : formatExpDate(education.end_date) }}
                  </div>
                  <div>{{ education.city }}, {{ education.state }}</div>
                </div>
                <div class="adv-exp-actions">
                  <b-btn
                    variant="secondary"
                    :disabled="isLoading"
                    @click.prevent="openEducationModal(education, index)"
                    size="sm"
                    class="mr-1"
                  >
                    <span>
                      <i class="fas fa-pen align-self-center"></i>
                      <strong class="ml-1">Edit</strong>
                    </span>
                  </b-btn>
                  <b-btn
                    variant="danger"
                    :disabled="isLoading"
                    @click.prevent="removeExpEduItem(index, 'education', 'education')"
                    size="sm"
                  >
                    <span>
                      <i class="fas fa-trash align-self-center"></i>
                      <strong class="ml-1">Remove</strong>
                    </span>
                  </b-btn>
                </div>
              </div>
            </div>

            <i v-if="!get(advisor, 'education.length')">There is no education history present.</i>
          </b-form-group>

          <!-- Specialities -->

          <b-form-group label-class="form-label">
            <template #label>
              <div class="d-flex justify-content-between">
                Specialities
                <b-btn variant="primary" :disabled="isLoading" @click.prevent="addSpeciality()" size="sm">
                  <span>
                    <i class="fas fa-plus align-self-center"></i>
                    <strong class="ml-1">Add Speciality</strong>
                  </span>
                </b-btn>
              </div>
            </template>

            <div
              v-for="(v, index) in $v.advisor.expertise.$each.$iter"
              :key="`adv-spec-${v.id}`"
              class="d-flex align-items-center mb-2"
            >
              <div class="flex-grow-1 mr-2">
                <b-form-input
                  :id="`speciality-${v.id}`"
                  placeholder="Enter speciality"
                  v-model.trim="v.name.$model"
                  :state="!v.name.required && v.name.$dirty ? false : null"
                  maxlength="50"
                />
                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </div>
              <div>
                <b-btn variant="danger" :disabled="isLoading" @click.prevent="removeSpeciality(index)" size="sm">
                  <span>
                    <i class="fas fa-trash align-self-center"></i>
                    <strong class="ml-1">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>

            <i v-if="!get(advisor, 'expertise.length')">There is no specialities added.</i>
          </b-form-group>

          <b-form-group label-class="form-label">
            <template #label>
              <div class="d-flex justify-content-between">
                <div class="mr-1">
                  <div>Affiliations</div>
                  <small style="font-size: 12px"
                    >Add logos for the organizations/entities the advisor is affiliated with</small
                  >
                </div>
                <div>
                  <b-btn
                    variant="primary"
                    :disabled="isLoading"
                    @click.prevent="addAffiliation()"
                    size="sm"
                    style="width: 130px"
                  >
                    <span>
                      <i class="fas fa-plus align-self-center"></i>
                      <strong class="ml-1">Add Affiliation</strong>
                    </span>
                  </b-btn>
                </div>
              </div>
            </template>

            <div
              v-for="(affiliation, index) in affiliations"
              :key="`adv-aff-${affiliation.id}`"
              class="d-flex align-items-center mb-2"
            >
              <div class="flex-grow-1 mr-2">
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="isImgUploading">...</span>
                    <b-img
                      :src="affiliation.image"
                      class="img-fluid"
                      width="40"
                      alt="Image"
                      v-else-if="affiliation.image"
                    />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>

                  <image-uploader
                    @image="(e) => uploadAffliationImage(e, index)"
                    :is-uploading="affiliation.isUploading"
                    placeholder="Select Image"
                  />
                </b-media>
              </div>
              <div>
                <b-btn variant="danger" :disabled="isLoading" @click.prevent="removeAffiliation(index)" size="sm">
                  <span>
                    <i class="fas fa-trash align-self-center"></i>
                    <strong class="ml-1">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>

            <i v-if="!get(affiliations, 'length')">There are no affiliations added.</i>
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot create an account with the given credentials. Please contact administrator.
          </div>

          <div class="form-group">
            <b-btn type="submit" variant="primary" block :disabled="isFormLoading" class="btn-normal">
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Register</span>
            </b-btn>
          </div>
        </b-form>
      </card>
    </div>

    <adv-exp-modal
      :show-modal="modals.showWorkExpModal"
      :work-exp="modals.selectedWorkExp"
      :exp-index="modals.selectedWorkExpIndex"
      @close="hideWorkExpModal"
      @closeUpdate="hideUpdateWorkExpModal"
    >
    </adv-exp-modal>

    <adv-education-modal
      :show-modal="modals.showEducationModal"
      :education="modals.selectedEducation"
      :item-index="modals.selectedEduIndex"
      @close="hideEducationModal"
      @closeUpdate="hideUpdateEducationModal"
    >
    </adv-education-modal>
  </div>
</template>

<script>
import { get, orderBy } from 'lodash';
import Card from '@/components/Ui/Card.vue';
import { mapActions } from 'vuex';
import Page from '@/components/Page';
import { FmvAvatar } from 'fmv-avatar';
import { titleize, parseFileNameFromUrl } from '@/common/utils';
import { required, email, url } from 'vuelidate/lib/validators';
import { PHONE_FORMAT_REGEX } from '@/common/constants';
import Editor from '@tinymce/tinymce-vue';
import ImageUploader from '@/components/ImageUploader.vue';
import AdvExpModal from '@/components/Advisors/AdvExpModal.vue';
import AdvEducationModal from '@/components/Advisors/AdvEducationModal.vue';
import moment from 'moment';
import { uuid } from 'vue-uuid';

export default {
  name: 'AdvisorSignup',
  components: { Card, Editor, ImageUploader, AdvExpModal, AdvEducationModal, FmvAvatar },
  extends: Page,
  layout: 'blank',

  data() {
    return {
      title: 'Request for new Advisor',

      advisor: {
        image: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        description: '',
        education: [],
        expertise: [],
        experience: [],
        affiliations: [],
        cta_link: '',
        cta_text: '',
        universities: [],
        advisor_association: '',
      },
      affiliations: [],

      universitiesOptions: [],
      entity: {},

      error: false,
      isLoading: false,
      isImgUploading: false,

      modals: {
        showWorkExpModal: false,
        selectedWorkExp: null,
        selectedWorkExpIndex: null,

        showEducationModal: false,
        selectedEducation: null,
        selectedEduIndex: null,
      },
    };
  },

  validations() {
    return {
      advisor: {
        image: { required },
        first_name: { required },
        last_name: { required },
        email: { required, email },
        phone: {
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
        description: { required },
        expertise: {
          $each: { name: { required } },
        },
        cta_link: { url },
      },
    };
  },

  computed: {
    errorState() {
      return this.error ? false : undefined;
    },

    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.affiliations.some((a) => a.isUploading);
    },

    hasOrgInvite() {
      return !!this.$route.params.inviteId;
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('advisors', ['signupAdvisorFromInvite']),
    ...mapActions('organization', ['getOrganizationFromInvite']),
    ...mapActions('employer', ['getEmployerUploadPresignedUrl']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('uni', ['getAllUniversitiesByState']),

    get,
    parseFileNameFromUrl,

    async setImage(file) {
      this.isImgUploading = true;
      this.advisor.image = await this.uploadImage(file);
      this.isImgUploading = false;
    },

    async uploadAffliationImage(file, index) {
      this.affiliations[index].isUploading = true;
      this.affiliations[index].image = await this.uploadImage(file);
      this.affiliations[index].isUploading = false;
    },

    async uploadImage(file) {
      try {
        const urlResp = await this.getEmployerUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: 'advisors',
        });
        await this.uploadToPresignedUrl({ url: urlResp.upload_url, file });
        return urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: "Couldn't upload image. Please try again." });
      }
      return null;
    },

    async getEntity() {
      try {
        const res = await this.getOrganizationFromInvite({ invite_id: this.$route.params.inviteId });
        this.entity = res.data;
      } catch (e) {
        // TODO: error
      }
    },

    async fetchUniversities() {
      const response = await this.getAllUniversitiesByState({});
      this.universitiesOptions = response.data;
    },

    selectUniversity(id) {
      if (this.advisor.universities.includes(id)) {
        const index = this.advisor.universities.indexOf(id);
        if (index > -1) {
          this.advisor.universities.splice(index, 1);
        }
      } else {
        this.advisor.universities.push(id);
      }
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const affiliations = this.affiliations.filter((a) => a.image).map((a) => a.image);
          const data = {
            ...this.advisor,
            first_name: titleize(this.advisor.first_name),
            last_name: titleize(this.advisor.last_name),
            affiliations,

            ...(this.hasOrgInvite && {
              invite_id: this.$route.params.inviteId,
              advisor_association: 'organization',
            }),
            ...(!this.hasOrgInvite && { advisor_association: 'university' }),
          };

          await this.signupAdvisorFromInvite(data);
          this.$router.push({ name: 'advisor-onboarding-confirm', params: { submission: 'success' } });
        } catch (_e) {
          this.error = true;
          this.$router.push({ name: 'advisor-onboarding-confirm', params: { submission: 'failed' } });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    openWorkExpModal(workExp = null, index = null) {
      this.modals.selectedWorkExp = workExp;
      this.modals.selectedWorkExpIndex = index;
      this.modals.showWorkExpModal = true;
    },
    hideWorkExpModal() {
      this.modals.showWorkExpModal = false;
      this.modals.selectedWorkExp = null;
      this.modals.selectedWorkExpIndex = null;
    },
    hideUpdateWorkExpModal(data) {
      this.modals.showWorkExpModal = false;
      this.modals.selectedWorkExp = null;
      this.modals.selectedWorkExpIndex = null;

      if (data.isUpdate) {
        this.advisor.experience[data.index] = data.workExp;
      } else {
        this.advisor.experience.push(data.workExp);
      }
      this.advisor.experience = orderBy(this.advisor.experience, ['still_working', 'start_date'], ['desc', 'desc']);
    },

    openEducationModal(education = null, index = null) {
      this.modals.selectedEducation = education;
      this.modals.selectedEduIndex = index;
      this.modals.showEducationModal = true;
    },
    hideEducationModal() {
      this.modals.showEducationModal = false;
      this.modals.selectedEducation = null;
      this.modals.selectedEduIndex = null;
    },
    hideUpdateEducationModal(data) {
      this.modals.showEducationModal = false;
      this.modals.selectedEducation = null;
      this.modals.selectedEduIndex = null;

      if (data.isUpdate) {
        this.advisor.education[data.index] = data.education;
      } else {
        this.advisor.education.push(data.education);
      }
      this.advisor.education = orderBy(this.advisor.education, ['still_attending', 'start_date'], ['desc', 'desc']);
    },

    async removeExpEduItem(index, type, itemKey) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected ${type}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.advisor[itemKey].splice(index, 1);
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    addSpeciality() {
      this.advisor.expertise.push({ name: '', id: uuid.v4() });
    },
    removeSpeciality(index) {
      this.advisor.expertise.splice(index, 1);
    },

    addAffiliation() {
      this.affiliations.push({ image: '', isUploading: false, id: uuid.v4() });
    },
    removeAffiliation(index) {
      this.affiliations.splice(index, 1);
    },

    formatExpDate(date) {
      return moment(date).format('MMMM, YYYY');
    },
  },

  mounted() {
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
    if (this.hasOrgInvite) {
      this.advisor.advisor_association = 'organization';
      this.getEntity();
    } else {
      this.advisor.advisor_association = 'university';
      this.fetchUniversities();
    }
  },
};
</script>

<style lang="scss" scoped>
.adv-exp-item {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.adv-exp-actions {
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
}
.adv-unis-list {
  @media (min-width: 768px) {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>