<template>
  <div class="page-section mt-5">
    <div class="container">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="page-section d-flex justify-content-center">
            <div class="card card-block card-stretch card-height col-md-8">
              <div class="card-body text-center">
                <div class="d-flex justify-content-center my-4">
                  <b-skeleton type="avatar" style="font-size: 1.1rem"></b-skeleton>
                </div>
                <div class="d-flex justify-content-center">
                  <b-skeleton width="50%" class="mb-4"></b-skeleton>
                </div>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="75%"></b-skeleton>
                </p>
                <div class="d-flex justify-content-center">
                  <b-skeleton type="button" width="160px"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i
                v-if="status === EMAIL_SUBSCRIPTION_STATUS.INVALID_TOKEN"
                class="fas fa-times-circle fa-lg text-danger mt-4 mb-4"
                style="font-size: 3rem"
              />
              <i v-else class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />

              <h4>
                {{
                  status === EMAIL_SUBSCRIPTION_STATUS.DISABLED
                    ? 'Email Unsubscribed Sucessfully'
                    : status === EMAIL_SUBSCRIPTION_STATUS.ENABLED
                    ? 'Email Resubscribed Successfully!'
                    : 'Failed'
                }}
              </h4>

              <p class="text-muted" v-if="status === EMAIL_SUBSCRIPTION_STATUS.DISABLED">
                {{
                  `Your unsubscribe email request has been submitted. You are now successfully unsubscribed from email subscription!`
                }}
              </p>
              <p class="text-muted" v-else-if="status === EMAIL_SUBSCRIPTION_STATUS.ENABLED">
                Your resubscribe email request has been submitted. You are now successfully subscribed to email
                subscription.
              </p>
              <p class="text-muted" v-else>
                Your request cannot be submitted. Please try again later or contact the administrator.
              </p>

              <b-button
                v-if="status === EMAIL_SUBSCRIPTION_STATUS.DISABLED"
                @click.prevent="updateStatus(EMAIL_SUBSCRIPTION_STATUS.ENABLED)"
              >
                Resubscribe
              </b-button>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page';
import { mapActions } from 'vuex';
import { EMAIL_SUBSCRIPTION_STATUS } from '@/common/constants';
export default {
  name: 'SubscriptionComponent',
  extends: Page,
  data() {
    return {
      title: 'Subscription',
      isUnsubscribed: false,
      status: null,
      isLoading: true,
      EMAIL_SUBSCRIPTION_STATUS,
    };
  },
  methods: {
    ...mapActions('emailSubscription', ['updateEmailSubscriptionStatus']),
    async updateStatus(status) {
      this.isLoading = true;

      try {
        const res = await this.updateEmailSubscriptionStatus({
          token: this.$route.query.token,
          subscription_status: status,
        });
        this.status = res.status_change;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.$route.query.token) {
      this.updateStatus(EMAIL_SUBSCRIPTION_STATUS.DISABLED);
    }
  },
};
</script>

<style>
</style>