<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px">
      <div class="row mt-5 mb-5" v-if="getCurrentSchool">
        <div class="d-flex justify-content-center col-6 align-items-center border-right border-secondary py-2">
          <div>
            <b-img :src="getCurrentSchool.logo_url" width="120" :alt="getCurrentSchool.name" />
          </div>
        </div>

        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="transition_connect_logo" width="200" height="auto" :alt="brand" />
          </div>
        </div>
      </div>
      <div class="text-center mt-5 mb-5" v-else>
        <b-img :src="logo" width="200" :alt="brand" />
      </div>
      <!-- Brand: Use if needed -->
      <!-- <div class="d-flex justify-content-center mb-4 navbar-light">
        <component :is="linkComponent" class="navbar-brand m-0" v-text="brand" />
      </div> -->
      <card
        :title="$t('authMsgs.login')"
        :subtitle="$t('authMsgs.topText')"
        header-class="text-center"
        class="navbar-shadow"
      >
        <slot />

        <!-- Google Auth (Use if needed) -->
        <!-- <template v-if="authWithGoogle">
          <b-btn variant="light" exact block class="mb-16pt" @click.prevent="onAuthWithGoogle">
            <span class="fab fa-google mr-2" />
            {{ $t('authMsgs.continueWithGoogle') }}
          </b-btn>

          <page-separator :title="$t('generalMsgs.or')" text-class="bg-white" center />
        </template> -->

        <b-form @submit.prevent="onSubmit">
          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <fmv-input-group-merge
              id="email"
              v-model="form.email"
              :state="errorState"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              required
              prepend
            >
              <span class="fas fa-envelope" />
            </fmv-input-group-merge>
          </b-form-group>

          <b-form-group :label="$t('authMsgs.password')" label-for="password" label-class="form-label">
            <div style="position: relative; display: flex; align-items: center; justify-content: flex-end">
              <fmv-input-group-merge
                id="password"
                v-model="form.password"
                :state="errorState"
                :placeholder="$t('authMsgs.password')"
                :type="!showPass ? 'password' : 'text'"
                class="form-control-prepended padding-pass"
                required
                prepend
              >
                <span class="fas fa-key" />
              </fmv-input-group-merge>

              <b-btn
                style="position: absolute; margin-right: 6px; z-index: 9"
                variant="flush"
                @click.prevent="() => (showPass = !showPass)"
              >
                <md-icon>{{ showPass ? 'visibility_off' : 'visibility' }}</md-icon>
              </b-btn>
            </div>
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            {{ $t('authMsgs.invalidEmailPass') }}
          </div>

          <div class="form-group">
            <b-btn type="submit" variant="primary" block :disabled="isLoading" style="height: 2.3rem">
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>{{ $t('authMsgs.login') }}</span>
            </b-btn>
          </div>

          <!-- Text Place (Use if needed!) -->
          <div class="text-center">
            <component
              :is="linkComponent"
              :to="routeTo('forgot-password')"
              class="text-black-70"
              style="text-decoration: underline"
              v-text="`${$t('authMsgs.forgotPass')}?`"
            />
          </div>
        </b-form>

        <div slot="footer" class="text-center text-black-50">
          <div
            v-if="getLoggedInUserDashboard !== DASHBOARD_TYPES.ORGANIZATION"
            class="d-flex justify-content-center mt-0 mb-1"
          >
            Need a student account?
            <a class="ml-1">
              <router-link :to="{ name: 'student-signup' }" style="text-decoration: underline"> Sign up</router-link></a
            >
          </div>
          <div v-if="domainCheck()" class="d-flex justify-content-center mt-0">
            Looking for employer login?
            <a class="ml-1" :href="`${getClickableLink(mainDomain)}/login`" style="text-decoration: underline">
              Click here</a
            >
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Card from '@/components/Ui/Card.vue';
import { get } from 'lodash';
import { FmvInputGroupMerge } from 'fmv-input-group-merge';
import Page from '@/components/Page';
import { DEFAULT_FAVICON, USER_ROLES, USER_ROLE_TYPES, DASHBOARD_TYPES } from '../../common/constants';
import logo from '@/assets/images/logos/logo_blue.png';
import transition_connect_logo from '@/assets/images/logos/transition_connect_tp.png';

import { captureException } from '@sentry/vue';

export default {
  components: {
    // PageSeparator,
    Card,
    FmvInputGroupMerge,
  },
  extends: Page,
  layout: 'blank',
  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
    linkComponent: {
      type: [String, Object],
      default: () => 'b-link',
    },
  },
  data() {
    return {
      title: 'Login',
      form: {
        email: null,
        password: null,
      },
      error: false,
      isLoading: false,
      showPass: false,
      transition_connect_logo,
      mainDomain: process.env.VUE_APP_MAIN_DOMAIN,
      DASHBOARD_TYPES,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'getLoggedInUserDashboard']),
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('invite', ['getInvalidToken']),
    ...mapGetters('signUpRequest', ['getSignupRequestStatus']),
    ...mapGetters('employer', ['getEmpSignupStatus']),

    errorState() {
      return this.error ? false : undefined;
    },
  },
  mounted() {
    if (this.getInvalidToken === true) {
      this.makeToast({
        variant: 'danger',
        msg: 'The invite link is either invalid or expired.',
        toaster: 'b-toaster-top-center',
      });
    }
    if (this.getSignupRequestStatus === true) {
      this.makeToast({
        variant: 'info',
        msg: 'Signup request received. We will notify you when your request has been approved.',
        toaster: 'b-toaster-top-center',
      });
    }

    if (this.getEmpSignupStatus === true) {
      this.makeToast({
        variant: 'info',
        msg: 'Successfully registered. Please verify your email address and login using your credentials.',
        toaster: 'b-toaster-top-center',
      });
    }

    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
    this.updateInviteTokenInvalid(false);
    this.updateSignupRequestStatus(false);
    this.updateEmpSignupStatus(false);
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('invite', ['updateInviteTokenInvalid']),
    ...mapActions('signUpRequest', ['updateSignupRequestStatus']),
    ...mapActions('employer', ['updateEmpSignupStatus']),
    ...mapActions('school', ['getSchoolPaymentStatus']),
    domainCheck() {
      return window.location.host !== process.env.VUE_APP_MAIN_DOMAIN ? true : false;
    },
    onClick() {
      this.$router.push({
        path: `${process.env.VUE_APP_MAIN_DOMAIN}/login`,
      });
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
    getErrors(key) {
      if (this.errors && this.errors[key] !== undefined) {
        return this.errors[key];
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const resp = await this.$store.dispatch('auth/login', this.form);
        if (
          resp.user.role_type === USER_ROLE_TYPES.SCHOOL ||
          // resp.user.role_type === USER_ROLE_TYPES.STUDENT ||
          resp.user.role_type === USER_ROLE_TYPES.ORGANIZATION
        ) {
          document.documentElement.style.setProperty('--primary-color', resp.user.linked_entity.primary_color);
          document.documentElement.style.setProperty('--secondary-color', resp.user.linked_entity.secondary_color);
          document.documentElement.style.setProperty('--dark-color', resp.user.linked_entity.dark_color);
          const favicon = document.getElementById('favicon');
          favicon.href = get(resp.user, 'linked_entity.small_logo_url') || DEFAULT_FAVICON;
        }

        if (resp.user.role_type === USER_ROLE_TYPES.EMPLOYER && get(resp.user.linked_entity, 'organization')) {
          document.documentElement.style.setProperty(
            '--primary-color',
            get(resp.user.linked_entity, 'organization.primary_color'),
          );
          document.documentElement.style.setProperty(
            '--secondary-color',
            get(resp.user.linked_entity, 'organization.secondary_color'),
          );
          document.documentElement.style.setProperty(
            '--dark-color',
            get(resp.user.linked_entity, 'organization.dark_color'),
          );
        }

        if (
          this.isLoggedIn &&
          ([USER_ROLE_TYPES.SCHOOL].includes(resp.user.role_type) ||
            ([USER_ROLE_TYPES.STUDENT].includes(resp.user.role_type) &&
              this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL))
        ) {
          this.$store.dispatch('school/getSchoolPaymentStatus');
        }

        if (resp.user.role === USER_ROLES.STUDENT) {
          const stdProfile = await this.$store.dispatch('student/getMyStudentProfile');
          // this.$store.dispatch('school/getSchoolPaymentStatus');
          this.$store.dispatch('student/getPiiStatus');
          this.setSettings({ layout: { layout: 'fixed' } });
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (!stdProfile.is_profile_complete) {
            this.$router.push({ name: 'student-onboarding' });
          } else {
            this.$router.push({ name: 'home' });
          }
        } else {
          this.setSettings({ layout: { layout: 'sticky' } });
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: 'home' });
          }
        }
      } catch (_e) {
        this.error = true;
        captureException(_e);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-pass ::v-deep .form-control-prepended {
  padding-right: 23px;
}
</style>
