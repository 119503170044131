<template>
  <b-modal
    ref="schoolTOSModal"
    hide-footer
    title="Transition Terms of Service"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <p><b>Last updated: August 1, 2024</b></p>
    <p>
      Welcome to Transition! These Terms of Service (“Terms”) govern your use of our platform. Please read them
      carefully.
    </p>
    <h4>1. Partnership for Schools and Businesses</h4>
    <h5>1.1 Program Listing</h5>
    <ul>
      <li>
        <b>Eligibility:</b> Schools and educational businesses can partner with Transition to list their programs for
        pre-health students.
      </li>
      <li>
        <b>Program Information:</b> Provide accurate and up-to-date information about your programs, including details
        such as program name, tuition range, pass rate, program duration, and student capacity.
      </li>
      <li>
        <b>Review and Approval:</b> All program listings are subject to review and approval by Transition. We reserve
        the right to remove listings that do not meet our standards or violate our policies.
      </li>
      <li>
        <b>Visibility:</b> Listing your programs on Transition increases visibility among potential students and helps
        them easily find and access your offerings.
      </li>
    </ul>
    <h5>1.2 Student Registration and Payments</h5>
    <ul>
      <li>
        <b>TransitionEnroll:</b> If your school or business wants students to register and pay for classes, you can use
        our technology platform, TransitionEnroll, to manage the enrollment process.
      </li>
      <li>
        <b>Features:</b> TransitionEnroll provides tools for class registration, payment processing, and managing
        student information.
      </li>
      <li>
        <b>Integration:</b> Partner schools and businesses can integrate TransitionEnroll with their existing systems to
        streamline the registration and payment process for students.
      </li>
    </ul>
    <h4>2. Acceptance of Terms</h4>
    <p>By using Transition, you agree to these Terms. If you don’t agree, please don’t use our platform.</p>
    <h4>3. Eligibility</h4>
    <p>You must be at least 18 years old to use Transition.</p>
    <h4>4. Changes to Terms</h4>
    <p>
      We may update these Terms from time to time. Changes take effect when posted. Continuing to use Transition means
      you accept the new Terms.
    </p>
    <h4>5. Account Registration</h4>
    <ul>
      <li>
        <b>Registration:</b> To use certain features, you need to create an account. Provide accurate information.
      </li>
      <li>
        <b>Security:</b> Keep your account details confidential. Notify us immediately if you suspect unauthorized use.
      </li>
    </ul>
    <h4>6. Use of Transition</h4>
    <ul>
      <li><b>License:</b> You can use Transition for personal, non-commercial purposes.</li>
      <li>
        <b>Prohibited Activities:</b> Do not use Transition for illegal activities, impersonate others, interfere with
        the platform, or use automated means to access Transition.
      </li>
    </ul>
    <h4>7. Content</h4>
    <ul>
      <li>
        <b>User Content:</b> You retain rights to your content but grant us a license to use it in connection with
        Transition.
      </li>
      <li><b>Prohibited Content:</b> Do not submit illegal or harmful content.</li>
    </ul>
    <h4>8. Intellectual Property</h4>
    <p>
      Transition and its content are protected by intellectual property laws. Respect our trademarks and copyrights.
    </p>
    <h4>9. Privacy</h4>
    <p>Your use of Transition is subject to our Privacy Policy.</p>
    <h4>10. Disclaimers and Liability</h4>
    <ul>
      <li><b>Disclaimers:</b> Transition is provided “as is.” We make no warranties.</li>
      <li><b>Limitation of Liability:</b> We are not liable for any indirect or consequential damages.</li>
    </ul>
    <h4>11. Indemnification</h4>
    <p>
      You agree to indemnify Transition for any claims arising from your use of the platform or violation of these
      Terms.
    </p>
    <h4>12. Governing Law</h4>
    <p>
      These Terms are governed by the laws of [Your State]. Any disputes will be resolved in the courts of [Your State].
    </p>
    <h4>13. Termination</h4>
    <p>We may suspend or terminate your account if you violate these Terms.</p>
    <h4>14. Contact Us</h4>
    <p>
      If you have questions about these Terms or the partnership opportunities, contact us at
      <a href="mailto:kayla@jointransiton.com" class="text-primary">kayla@jointransiton.com</a>
    </p>
  </b-modal>
</template>

<script>
export default {
  name: 'TOSModal',

  props: {
    showModal: { type: Boolean, default: false },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.schoolTOSModal.show();
      }
    },
  },

  methods: {
    hideModal() {
      this.$emit('update:showModal', false);
    },
  },
};
</script>
