<template>
  <vue-recaptcha
    :sitekey="siteKey"
    :load-recaptcha-script="true"
    type="V2"
    @verify="handleSuccess"
    @error="handleError"
  ></vue-recaptcha>
</template>

<script >
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'ReCaptcha',
  components: {
    VueRecaptcha,
  },
  methods: {
    handleError() {
      // Do some validation
    },

    handleSuccess(token) {
      this.$emit('getToken', token);
    },
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    },
  },
};
</script>
