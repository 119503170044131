<template>
  <div style="padding-top: 16px" id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->

    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-dollar-sign fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>Upgrade Payment Plan</h4>

              <p class="text-muted">
                Upgrade your plan to get access to community features including skills and questions. For more details
                contact us at
                <a :href="`mailto:corey@jointransition.com`" class="text-primary">corey@jointransition.com.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';

export default {
  extends: Page,
  data() {
    return {
      title: 'Upgrade Plan',
    };
  },
};
</script>

<style>
</style>